body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #0f0f0f;
    overflow-x: hidden;
    transition: background-color 0.3s ease-in-out;
}

.landing-page {
    text-align: center;
}

.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to bottom, #d036ff, #0f0f0f);
    animation: fadeIn 1s ease-in;
}

.fade-out {
    animation: fadeOut 0.5s ease-in-out forwards;
}

.fade-in {
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.loading-animation {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    border-top: 5px solid #d036ff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.navbar {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.7);
    position: sticky;
    top: 0;
    z-index: 100;
    backdrop-filter: blur(10px);
    animation: slideDown 0.8s ease-in-out;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.navbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
}

.navbar a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    transition: color 0.3s;
}

.navbar a:hover {
    color: #d036ff;
}

.top-section {
    position: relative;
    height: 100vh;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url('/public/Test.png') center/cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes zoomIn {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

.text-slide-in {
    animation: slideIn 1s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.text-fade-in {
    animation: fadeIn 1.5s ease-in-out;
}

.main-name {
    font-size: 64px;
}

.wip-text {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
}

.cbl-text {
    font-size: 24px;
    font-weight: 600;
    color: #714175;
}

.footer {
    background: #0f0f0f;
    color: white;
    padding: 10px 20px;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
}
